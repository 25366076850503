import marketService from 'services/market'
import { formatNumber } from 'utils/format-number'
import initSlider from './slider'

export type SelectName = 'select-hero-base' | 'select-hero-counter'

export interface CustomerRequest {
  baseValue?: string
  baseCurrency?: string
  counterValue?: string
  counterCurrency?: string
  direction?: string
}

const $portraits = document.querySelectorAll<HTMLDivElement>('[data-js-selector="portrait"]')
const $portraitsWrapper = document.querySelector<HTMLDivElement>('[data-js-selector="portrait-wrapper"]')

init()

function init(): void {
  marketService.fetchRates().then(() => {
    startInterval()
    assignRateToExchangeSection()
  })

  handleSEOLinks()
  handlePortraits()
  initSlider()
}

function startInterval(): void {
  setInterval(() => {
    marketService.fetchRates().then(assignRateToExchangeSection)
  }, 10 * 1000) // 10 seconds
}

function assignRateToExchangeSection(): void {
  const $rateItems = document.querySelectorAll<HTMLElement>('[data-rate-item]')
  for (const $item of $rateItems) {
    const market = marketService.getMarketBrief().find((el) => el.pair === $item.dataset.rateItem)
    $item.querySelector('[data-js-selector="buy-rate"]').innerHTML = formatNumber(market.directExchangeOffers.buyNow, 4)
    $item.querySelector('[data-js-selector="sell-rate"]').innerHTML = formatNumber(
      market.directExchangeOffers.sellNow,
      4,
    )
  }
}

function handlePortraits(): void {
  if (!IntersectionObserver) {
    $portraits[0].classList.add('show-portrait')
    return
  }

  const observer = new IntersectionObserver(onIntersection, { threshold: 0.5 })
  observer.observe($portraitsWrapper)
}

function showPortrait($portrait: HTMLElement): void {
  $portrait.classList.add('show-portrait')
}

function hidePortrait($portrait: HTMLElement): void {
  $portrait.classList.add('hide-portrait')
}

function onIntersection(entries: IntersectionObserverEntry[]): void {
  if (entries[0].isIntersecting && !$portraitsWrapper.classList.contains('intersected')) {
    $portraitsWrapper.classList.add('intersected')

    for (const [index, $portrait] of $portraits.entries()) {
      $portrait.addEventListener('animationend', function listener(event: AnimationEvent) {
        if (event.animationName === 'showPortrait') {
          setTimeout(() => hidePortrait($portrait), 3000)
        }

        if (event.animationName === 'hidePortrait') {
          $portrait.classList.remove('show-portrait', 'hide-portrait')
          $portrait.removeEventListener('animationend', listener)
          showPortrait($portraits[index + 1] ? $portraits[index + 1] : $portraits[0])
        }
      })
    }

    showPortrait($portraits[0])
  }
}

function handleSEOLinks(): void {
  const $links = document.querySelectorAll<HTMLElement>('[data-seo-link]')
  for (const $link of $links) {
    $link.addEventListener('click', () => {
      window.location.href = $link.dataset.seoLink
    })
  }
}
