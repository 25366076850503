import Glide, { Controls, Autoplay, Swipe, Keyboard } from '@glidejs/glide/dist/glide.modular.esm'

declare let window: any

const AUTOPLAY_PERIOD = 8000 //ms

const initSlider = () => {
  const $sliderElement = document.querySelector<HTMLDivElement>('[data-glide-container]')
  if ($sliderElement) {
    const slider = new Glide($sliderElement, {
      type: 'carousel',
      perView: 1,
    })

    window.autoscrollEnabled = false
    const events = ['mousedown', 'mousemove', 'keydown', 'scroll', 'touchstart']
    events.forEach((eventName) => {
      window.addEventListener(
        eventName,
        () => {
          if (!window.autoscrollEnabled) {
            slider.play(AUTOPLAY_PERIOD)
            window.autoscrollEnabled = true
          }
        },
        { once: true },
      )
    })

    slider.mount({ Controls, Autoplay, Swipe, Keyboard })
  }
}

export default initSlider
